const burger = document.getElementById("burger");
const pgBody = document.querySelector('body');

const menuToggle = () => {
  pgBody.classList.toggle('touch-menu-active');
};

burger.addEventListener("click", menuToggle);

function defineCoefficientCssVariableForVhOnMobileDevices() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}
defineCoefficientCssVariableForVhOnMobileDevices();
